import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";


export default function AreYouSureDialog({
    handleYes,
    handleNo,
    dialogOpen,
    text,
    title
}) {
    return (
        <Dialog
            open={dialogOpen}
            onClose={handleNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleNo}>No</Button>
                <Button onClick={handleYes} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}