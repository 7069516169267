import { Link } from "react-router-dom";
import TopBar from "./TopBar";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';


import DiagramList from './DiagramList';
import AreYouSureDialog from "./utils/AreYouSureDialog";
import ShortUniqueId from 'short-unique-id'

import useLocalStorage from './utils/LocalStorage';
import { backupData, restoreData } from "./utils/BackupRestore";

import { useState } from 'react';
import HelpContent from "./HelpContent";
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function Main() {
    let newProjectId = new ShortUniqueId();
    console.log(newProjectId);
    const [database, setDatabase] = useLocalStorage("database");
    const [helpOpen, setHelpOpen] = useState(false);

    const resetAndReload = (data) => {
        setDatabase(JSON.parse(data));
        window.location.reload(false);
    }

    if (typeof database === 'undefined') {
        setDatabase({
            version: 1,
            type: "planted",
            projects: {},
        });
    }
    const [filter, setFilter] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <div>
            <Grid container spacing={2}>
                <TopBar />
                <Grid
                    container
                    style={{
                        marginTop: 10,
                        paddingLeft: 60,
                        paddingRight: 60,
                    }}
                >
                    <Grid
                        item
                        xs={8}
                        sx={{
                            display: 'flex',
                            '& > *': {
                                m: 1,
                            },
                        }}
                    >
                        <Link to={`/project/${new ShortUniqueId()()}`}><Button variant="contained">New Diagram</Button></Link>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        sx={{
                            display: 'flex',
                            '& > *': {
                                m: 1,
                            },
                        }}
                        style={{ width: "100%", alignSelf: "right" }}>
                        <Button onClick={() => setDialogOpen(true)} variant="contained">Restore</Button>
                        <Button onClick={() => backupData(database)} variant="contained">Backup</Button>
                        <Button onClick={() => setHelpOpen(true)} variant="contained" style={{ backgroundColor: 'red' }}><QuestionMarkIcon /></Button>
                        <Modal
                            open={helpOpen}
                            onClose={() => setHelpOpen(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <HelpContent />
                            </Box>
                        </Modal>
                        <AreYouSureDialog
                            handleNo={() => setDialogOpen(false)}
                            handleYes={() => {
                                setDialogOpen(false);
                                restoreData((data) => resetAndReload(data));
                            }}
                            dialogOpen={dialogOpen}
                            title="Overwrite data?"
                            text="Are you sure you want to replace the existing data with the data from a backup file?" />
                    </Grid>
                </Grid>
                <Grid
                    container
                    style={{
                        marginTop: 10,
                        paddingLeft: 60,
                        paddingRight: 60,
                    }}
                    sx={{
                        display: 'flex',
                        '& > *': {
                            m: 1,
                        },
                    }}
                >
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search Diagrams"
                            inputProps={{ 'aria-label': 'search diagrams' }}
                            onChange={(event) => setFilter(event.target.value)}
                        />
                        <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Grid>
                <Grid
                    container
                    style={{
                        marginTop: 10,
                        paddingLeft: 60,
                        paddingRight: 60,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            '& > *': {
                                m: 1,
                            },
                        }}
                    >
                        <DiagramList searchText={filter} />
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}
