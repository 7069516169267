import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from "@mui/icons-material/Delete";

import useLocalStorage from './utils/LocalStorage';
import { Link } from 'react-router-dom';

import moment from 'moment';
import { useState } from 'react';

export default function DiagramList(props) {

    const [database, setDatabase] = useLocalStorage("database");
    const [redraw, setRedraw] = useState(false)
    let projects = database.projects;

    var searchText = props.searchText;
    if (typeof searchText === 'undefined') {
        searchText = '';
    }

    const deleteProject = (projectId) => {
        delete database.projects[projectId];
        setDatabase(database);
        setRedraw(!redraw);
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 800 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Project Name</TableCell>
                        {/* <TableCell align="center">Type</TableCell> */}
                        <TableCell align="center">Create Date</TableCell>
                        <TableCell align="center">Last Update</TableCell>
                        <TableCell align="center">Revisions</TableCell>
                        <TableCell align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(projects).reverse().map((project) => (
                        project.name.includes(searchText) &&
                        < TableRow
                            key={project.projectId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="right">
                                <Link to={`/project/${project.projectId}`} key={project.projectId}>
                                    {project.name.length === 0 ? '-- Unnamed --' : project.name}
                                </Link>
                            </TableCell>
                            {/* <TableCell align="right">{project.type}</TableCell> */}
                            <TableCell align="right">{moment(project.createDate).format('M/D/YYYY HH:mma')}</TableCell>
                            <TableCell align="right">{project.updateDate == null ? '' : moment(project.updateDate).format('M/D/YYYY HH:mma')}</TableCell>
                            <TableCell align="right">{project.revisions.length}</TableCell>
                            <TableCell align="center">
                                <DeleteIcon onClick={() => {
                                    if (window.confirm('Are you sure you wish to delete this item?')) {
                                        deleteProject(project.projectId);
                                    }
                                }} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer >
    );
}